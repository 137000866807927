import React from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

interface PricingComponentProps {
  price: string;
  plan: string;
  description: string;
  features: { name: string; isEnabled: boolean }[];
  buttonText: string;
  isHighlighted?: boolean;
  linkTo: string; // Added for dynamic navigation
}

const PricingComponent: React.FC<PricingComponentProps> = ({ price, plan, description, features, buttonText, isHighlighted, linkTo }) => {
  return (
    <div className={`w-96 sm:w-72 dark:bg-gray-800 text-gray-700 dark:text-gray-300 rounded-lg lg:w-96 overflow-hidden shadow-lg p-6 ${isHighlighted ? 'dark:bg-gray-700 text-gray-700 dark:text-gray-300 border-1 border-gray-700' : 'dark:bg-gray-900 text-gray-700 dark:text-gray-300'}`}>
    
      <div className="text-center mb-4">
        <h2 className={`rounded-full ${isHighlighted ? 'dark:bg-gray-700 text-gray-700 dark:text-slate-300 py-1 text-3xl font-semibold' : ''} text-2xl font-semibold`}>{price}/شهر</h2>
        <p className="text-xl text-sky-600">{plan}</p>
        <p className="text-sm text-green-600">{description}</p>
      </div>
      <hr className='mb-10 mx-3 text-gray-600'/>
      <ul className="mb-6" dir='rtl'>
        {features.map((feature, index) => (
          <li key={index} className="flex items-center mb-2">
            {feature.isEnabled ? (
              <FaCheckCircle className="w-6 h-6 ml-2" color='#375896' />
            ) : (
              <FaTimesCircle className="w-6 h-6 ml-2 text-red-500" />
            )}
            <span className='my-1 font-semibold'>{feature.name}</span>
          </li>
        ))}
      </ul>
      <Link
        to={linkTo}
        className={`w-full py-2 rounded-full ${isHighlighted ? 'bg-[#30aaf0] text-white' : 'bg-white border border-gray-500 text-gray-700'} font-semibold text-center inline-block`}
      >
        {buttonText}
      </Link>
    </div>
  );
};

export default PricingComponent;
