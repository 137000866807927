import React, { useContext, useEffect, useState } from "react";
import DeviceMaster from "./DeviceMaster";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import UserDevices from "./UserDevices";

const UserMaster: React.FC = () => {

  const { rolePrivileges } = useContext(AuthContext);
  const location = useLocation();


  const [activeTab, setActiveTab] = useState<string>(
    rolePrivileges === 3 ? "users" : "devices"
  );

  // const getDeviceCountForUser = (userId: number) => {
  //   if (!deviceData || !deviceData.data) return 0;
  //   return deviceData.data.filter((device: any) => device.id_users === userId)
  //     .length;
  // };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabFromUrl =
      queryParams.get("tab") || (rolePrivileges === 3 ? "users" : "devices");
    setActiveTab(tabFromUrl);

  }, [location.search, rolePrivileges]);



  return (
    <div className="px-4 min-h-screen my-4">
      <div className="bg-white rounded-sm md max-h-screen px-0 overflow-y-auto hide-scrollbar">
        {/* Tabs */}
        <div className="flex gap-2 justify-end bg-gray-100 p-2 rounded-lg mb-2 mt-12">
          {rolePrivileges === 3 && (
            <button
              onClick={() => setActiveTab("users")}
              className={`py-2 px-2 ${
                activeTab === "users"
                  ? "bg-amber-50 rounded-md text-blue-500 font-bold"
                  : "text-gray-600 font-semibold"
              }`}
            >
              المستخدمين
             
            </button>
          )}
          <button
            onClick={() => setActiveTab("devices")}
            className={`py-2 px-2 ${
              activeTab === "devices"
                ? "bg-amber-50 rounded-md text-blue-500 font-bold"
                : "text-gray-600 font-semibold"
            }`}
          >
            الاجهزة المرتبطة بك
           
          </button>
        </div>

        {activeTab === "users" && (
          <div>
            <UserDevices/>
          </div>
        )}

        {activeTab === "devices" && <DeviceMaster />}
      </div>
    </div>
  );
};

export default UserMaster;
