import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  ChangeEvent,
} from "react";
import { useParams } from "react-router-dom";
import { DeviceMasterContext } from "../../../../context/devices/DeviceMasterContext";
import { AuthContext } from "../../../../context/AuthContext";
import axios from "axios";
import { Device } from "../../../../interfaces/Device";
import { FiRefreshCcw, FiCopy } from "react-icons/fi";

const ChangeDeviceToken: React.FC = () => {
  const { deviceId } = useParams<{ deviceId: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [deviceDetails, setDeviceDetails] = useState<Device | undefined>();
  const [DeviceToken, setToken] = useState<string>("");

  const [message, setMessage] = useState<{
    text: string;
    type: "success" | "error";
  } | null>(null);

  const { deviceData, fetchDeviceMaster } = useContext(DeviceMasterContext);
  const { token } = useContext(AuthContext);

  const fetchDeviceDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      if (deviceData && deviceData.data) {
        const foundDevice = deviceData.data.find(
          (device: Device) => device.deviceId === deviceId
        );
        if (foundDevice) {
          setDeviceDetails(foundDevice);
          setToken(foundDevice.Token);
        } else {
          console.warn("Device not found in deviceData.");
        }
      } else {
        console.warn("deviceData or deviceData data is undefined.");
      }
    } catch (error) {
      console.log("Error fetching device details:", error);
    } finally {
      setIsLoading(false);
    }
  }, [deviceId, deviceData]);

  useEffect(() => {
    fetchDeviceDetails();
  }, [fetchDeviceDetails]);

  const handleUpdateChange = async () => {
    if (deviceDetails) {
      setIsLoading(true);
      setMessage(null);

      const formData = new FormData();
      formData.append("deviceToken", deviceDetails.Token);
      formData.append("deviceId", deviceDetails.deviceId);

      try {
        const response = await axios.post(
          "https://whats1.sky.sky4system.com/api/user/changeToken",
          formData,
          {
            headers: {
              "auth-token": token,
            },
          }
        );
        // console.log(response.data)
        if (response.data.status === true) {
          setToken(response.data.devicetoken);
          fetchDeviceMaster();

          setTimeout(() => {
            setMessage({
              text: "بنجاح  api Key token تم تغيير",
              type: "success",
            });
          }, 1000);
        }
        await fetchDeviceDetails();
        return response.data;
      } catch (error) {
        console.log("Error updating api key URL:", error);

        setTimeout(() => {
          setMessage({
            text: "فشل في تحديث المفتاح.",
            type: "error",
          });
        }, 1000);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (message) {
      timeout = setTimeout(() => setMessage(null), 1000);
    }
    return () => clearTimeout(timeout);
  }, [message]);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(DeviceToken)
      .then(() => {
        setMessage({
          text: "تم النسخ للحافظة",
          type: "success",
        });
      })
      .catch((error) => {
        console.error("Failed to copy text:", error);
        setMessage({
          text: "Failed to copy token.",
          type: "error",
        });
      });
  };

  return (
    <div className="p-4">
      <div className="space-y-2">
        <div className="text-lg flex justify-end font-bold text-gray-700">
          <button
            onClick={handleUpdateChange}
            disabled={isLoading}
            className={`inline-flex justify-end gap-2 px-2 py-2 border-gray-400 text-sm font-medium text-gray-100 bg-blue-500 border border-transparent rounded-md hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-100 ${
              isLoading ? "cursor-not-allowed opacity-50" : ""
            }`}
          >
            <span>
              {isLoading ? " api Key token تغيير" : " api Key token تغيير"}
            </span>
            <FiRefreshCcw />
          </button>
        </div>
        <div className="space-y-1">
          <div className="flex gap-3 items-center">
            <input
              type="text"
              name="Token"
              value={DeviceToken || ""}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setToken(e.target.value);
              }}
              className={`flex-grow p-2 mt-4 border w-full rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 transition duration-300 ease-in-out transform hover:scale-105 ${
                message?.type === "error" ? "border-red-500" : ""
              }`}
              readOnly
            />
            <button
              onClick={handleCopy}
              className="flex-shrink-0 p-2 border border-gray-300 rounded-md text-gray-600 mt-4"
            >
              <FiCopy size={25} />
            </button>
          </div>

          {message && (
            <p
              className={`text-sm mt-1 ح-2 firework-message ${
                message.type === "error" ? "error-message" : ""
              }`}
            >
              {message.text}
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChangeDeviceToken;
