// src/components/MediaGroupComponent.tsx
import React from "react";
import { AiFillFileImage } from "react-icons/ai";
import MediaSendGroup from "./MediaSendGroup";

const MediaGroupComponent: React.FC = () => {
  return (
    <div className="border-b border-gray-300 justify-end container mt-14">
        <div className="flex justify-end gap-3 mx-16 text-lg mt-8">
        <span>ارسال ملفات للمجموعات</span>
        <AiFillFileImage size={25} className="text-cyan-600"/>
        </div>
      <MediaSendGroup />
    </div>
  );
};

export default MediaGroupComponent;
