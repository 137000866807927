import React, { useContext, useMemo, useState } from "react";
import Select from "react-select";
import { DeviceMasterContext } from "../../../../context/devices/DeviceMasterContext";
import { AuthContext } from "../../../../context/AuthContext";

interface OptionType {
  value: string;
  label: string;
  deviceDetails: any;
}

interface DeviceSelectProps {
  onDeviceSelect: (deviceDetails: any) => void;
}

const DeviceSelect: React.FC<DeviceSelectProps> = ({ onDeviceSelect }) => {
  const { deviceData } = useContext(DeviceMasterContext);
  const { userId } = useContext(AuthContext);
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);

  const options = useMemo(() => {
    if (!deviceData || !deviceData.data || !userId) return [];

    const authenticatedDevices = deviceData.data.filter(
      (device: any) => device.status === "AUTHENTICATED" && device.id_users === userId
    );

    return authenticatedDevices.map((device: any) => ({
      value: device.deviceId,
      label: device.name || "لا يوجد جهاز متصل",
      deviceDetails: device,
    }));
  }, [deviceData,userId]);

  const handleChange = (selected: OptionType | null) => {
    setSelectedOption(selected);
    if (selected) {
      onDeviceSelect(selected.deviceDetails);
    } else {
      onDeviceSelect(null);
    }
  };

  return (
    <div className="mt-8">
      <Select
        options={options}
        className="border border-blue-500"
        onChange={handleChange}
        value={selectedOption}
        placeholder="...اختار جهاز متصل"
      />
    </div>
  );
};

export default DeviceSelect;
