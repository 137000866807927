import React, { useContext, useMemo, useState } from "react";
import Select, { MultiValue, ActionMeta } from "react-select";
import { GroupsContext } from "../../../../context/contactsgroups/GroupsContext";
import './css/GroupsSelect.css';
import colourStyles from "./colourStyles";


interface OptionType {
  value: string;
  label: string;
  groupDetails?: any;
}

interface GroupsSelectProps {
  onGroupsSelect: (selectedGroupIds: string[]) => void;
}

const GroupsSelect: React.FC<GroupsSelectProps> = ({ onGroupsSelect }) => {
  const { GroupsData } = useContext(GroupsContext);
  const [selectedOptions, setSelectedOptions] = useState<OptionType[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const options = useMemo(() => {
    if (!GroupsData || !GroupsData.data) return [];

    return GroupsData.data.data.map((group: any) => {
      const numericId = group.id;

      return {
        value: numericId,
        label: group.name || "لا يوجد مجموعات",
        groupDetails: group,
      };
    });
  }, [GroupsData]);

  const handleChange = (newValue: MultiValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
    const selected = newValue as OptionType[];
    setSelectedOptions(selected);
    
    const selectedGroupIds = selected.map(option => option.value);
    // console.log('Selected Options:', selected);
    // console.log('Selected Group IDs:', selectedGroupIds);

    onGroupsSelect(selectedGroupIds);
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      // Deselect all
      setSelectedOptions([]);
      onGroupsSelect([]);
    } else {
      // Select all
      setSelectedOptions(options);
      const allGroupIds = options.map((option: { value: any; }) => option.value);
      onGroupsSelect(allGroupIds);
    }
    setSelectAll(!selectAll);
  };

  const customSelectAllOption = () => (
    <div className="select-all-option">
      <input
        type="checkbox"
        checked={selectAll}
        onChange={handleSelectAllChange}
        id="select-all"
      />
      <label htmlFor="select-all" className="ml-2">اختار الكل</label>
    </div>
  );

  return (
    <div className="mt-8">
      <Select
        options={options}
        isMulti
        className="border border-blue-500"
        onChange={handleChange}
        value={selectedOptions}
        placeholder="...اختار المجموعات "
        components={{ DropdownIndicator: customSelectAllOption }}
        classNamePrefix="react-select"
        closeMenuOnSelect={false}
        styles={colourStyles}
      />
    </div>
  );
};

export default GroupsSelect;
