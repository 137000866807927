/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import { motion } from "framer-motion";
import {
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelope,
  FaFacebook,
  FaLinkedin,
  FaInstagram,
} from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";

const ContactUs: React.FC = () => {
  return (
    <div
      className="p-8 bg-gray-200 rounded-3xl dark:bg-gray-800 mx-4 text-gray-700  dark:text-gray-300 mb-12 sm:max-w-6xl sm:mx-auto"
      dir="rtl"
    >
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, ease: "easeInOut" }}
        className="text-center mb-12"
      >
        <h1 className="text-4xl border-b-2 border-gray-700 dark:border-gray-300 font-semibold mb-3 inline-block pb-2">
          تواصل معنا
        </h1>
      </motion.div>

      <div className="grid grid-cols-1 md:grid-cols-2 items-center justify-center gap-8 mb-12">
        <div className="flex items-center gap-2 p-4">
          <FaMapMarkerAlt className="text-[#30aaf0] text-2xl" />
          <a
            href="https://maps.app.goo.gl/tmRSjS9jamVBsAcQ8"
            className="text-base hover:text-[#2a66b1]"
          >
            اليمن صنعاء - شارع المقالح - أمام بهارات بن ياسين
          </a>
        </div>
        <div className="flex items-center gap-2 p-4">
          <FaPhone className="text-[#2a66b1] text-2xl" />
          <a
            href="tel:+016886922"
            className="text-base hover:text-[#2a66b1]"
          >
            016886922{" "}
          </a>{" "}
          <a href="tel:+016886922" className="hover:text-[#2a66b1]">780088190</a>
        </div>
        <div className="flex items-center gap-2 p-4">
          <FaEnvelope className="text-[#2a66b1] text-2xl" />
          <a
            href="mailto:info@sky.com"
            className="text-base hover:text-[#2a66b1]"
          >
            info@sky.com
          </a>
        </div>
        <div className="flex items-center gap-6 justify-center md:justify-start">
          <a
            href="https://www.instagram.com/sky4system/?utm_source=qr&igsh=MW0zYTh0cWtlYXpqaw%3D%3Dsky4system/?utm_source=qr&igsh=MW0zYTh0cWtlYXpqaw%3D%3D"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#30aaf0] text-3xl hover:text-[#2a66b1] transition-colors"
          >
            <FaFacebook />
          </a>
          <a
            href="https://www.instagram.com/sky4system/?utm_source=qr&igsh=MW0zYTh0cWtlYXpqaw%3D%3D"
            className="text-[#30aaf0] text-3xl hover:text-[#2a66b1] transition-colors"
          >
            <FaInstagram size={30} />
          </a>
          <a
            href="https://x.com/skysystems48902?t=o8j4z4cNZW13mnkTVN1-wg&s=09"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#30aaf0] text-3xl hover:text-[#2a66b1] transition-colors"
          >
            <BsTwitterX />
          </a>
          <a
            href="https://www.linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#30aaf0] text-3xl hover:text-[#2a66b1] transition-colors"
          >
            <FaLinkedin />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
