import { StylesConfig } from "react-select";

interface PhoneOption {
  label: string;
  value: string;
}

const colourStyles: StylesConfig<PhoneOption, true> = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#49c6ff"
        : isFocused
        ? "#e0f7ff"
        : undefined,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? "white"
        : "black",
      cursor: isDisabled ? "not-allowed" : "default",
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled ? (isSelected ? "#49c6ff" : "#b3ecff") : undefined,
      },
    };
  },
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: "#e0f7ff",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: "black",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "black",
    ":hover": {
      backgroundColor: "#49c6ff",
      color: "white",
    },
  }),
};

export default colourStyles;
