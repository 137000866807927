import React, { useContext, useState, useEffect } from "react";
import { PlanContext } from "../../../context/devices/PlanContext";
import { FaSpinner } from "react-icons/fa";
import { AuthContext } from "../../../context/AuthContext";
import handleUpdateChange from "./price/handleUpdateChange";
import { RiDeleteBin5Fill } from "react-icons/ri";

type Feature = {
  name: string;
  isEnabled: boolean;
};

const UpdatePlan: React.FC = () => {
  const { planData, isloading, fetchPlanMaster } = useContext(PlanContext);
  const { token } = useContext(AuthContext);
  const [message, setMessage] = useState<{
    text: string;
    type: "success" | "error";
  } | null>(null);
  const [editPlan, setEditPlan] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);
  const [dynamicFields, setDynamicFields] = useState<
    { key: string; value: any }[]
  >([]);
  const [features, setFeatures] = useState<Feature[]>([]);

  useEffect(() => {
    if (editPlan && editPlan.data) {
      try {
        const parsedPlanData =
          typeof editPlan.data === "string"
            ? JSON.parse(editPlan.data)
            : editPlan.data;

        setEditPlan((prevState: any) => ({
          ...prevState,
          data: parsedPlanData,
        }));

        if (parsedPlanData.features) {
          setFeatures(parsedPlanData.features);
        }

        if (typeof parsedPlanData === "object") {
          const fields = Object.keys(parsedPlanData).map((key) => ({
            key,
            value: parsedPlanData[key],
          }));
          setDynamicFields(fields);
        }
      } catch (error) {
        console.error("Error parsing data:", error);
      }
    }
  }, [editPlan?.data]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (name.startsWith("dynamicField_")) {
      // Handle text input changes
      const dynamicFieldName = name.replace("dynamicField_", "");
      setEditPlan((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          [dynamicFieldName]: value,
        },
      }));
    } else if (name.startsWith("dynamicFieldCheckbox_")) {
      // Handle checkbox changes
      const dynamicFieldName = name.replace("dynamicFieldCheckbox_", "");
      setEditPlan((prevState: any) => ({
        ...prevState,
        data: {
          ...prevState.data,
          [dynamicFieldName]: checked ? "true" : "false",
        },
      }));
    } else {
      // Handle static fields
      setEditPlan((prevState: any) => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleFeatureChange = <K extends keyof Feature>(
    index: number,
    key: K,
    value: Feature[K]
  ) => {
    const updatedFeatures = [...features];
    updatedFeatures[index][key] = value;
    setFeatures(updatedFeatures);
    setEditPlan((prevState: any) => ({
      ...prevState,
      data: {
        ...prevState.data,
        features: updatedFeatures,
      },
    }));
  };

  const addFeature = () => {
    setFeatures([...features, { name: "", isEnabled: false }]);
  };

  const removeFeature = (index: number) => {
    const updatedFeatures = features.filter((_, i) => i !== index);
    setFeatures(updatedFeatures);
    setEditPlan((prevState: any) => ({
      ...prevState,
      data: {
        ...prevState.data,
        features: updatedFeatures,
      },
    }));
  };

  const handleEdit = (plan: any) => {
    setEditPlan(plan);
  };

  const handleCancelEdit = () => {
    setEditPlan(null);
  };

  if (isloading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-50">
        <FaSpinner className="animate-spin text-blue-600" size={32} />
      </div>
    );
  }
  return (
    <div className="mt-12 px-4 sm:px-6 lg:px-8" dir="rtl">
      <div className="flex justify-center">
        <span className="text-3xl font-bold mt-8 text-gray-600 mb-4">
          عروض الأسعار
        </span>
      </div>
      {planData?.data ? (
        planData.data.map((plan: any) => (
          <div
            key={plan.id}
            className="bg-white mx-auto max-w-4xl shadow-lg rounded-lg p-8 mb-6 hover:shadow-xl"
          >
            {editPlan?.id === plan.id ? (
              // Edit mode form
              <div>
                {/* Static Fields */}
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    عنوان العرض:
                  </label>
                  <input
                    type="text"
                    name="title"
                    value={editPlan.title || ""}
                    onChange={handleInputChange}
                    className="p-2 mt-4 border w-full rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    السعر:
                  </label>
                  <input
                    type="number"
                    name="price"
                    value={editPlan.price || ""}
                    onChange={handleInputChange}
                    className="p-2 mt-4 border w-full rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    أيام العرض:
                  </label>
                  <input
                    type="number"
                    name="days"
                    value={editPlan.days || ""}
                    onChange={handleInputChange}
                    className="p-2 mt-4 border w-full rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    زر طلب السعر:
                  </label>
                  <input
                    type="text"
                    name="buttonText"
                    value={editPlan.buttonText || ""}
                    onChange={handleInputChange}
                    className="p-2 mt-4 border w-full rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                  />
                </div>
                <div className="mb-4">
                  <label className="inline-flex gap-2 items-center">
                    <input
                      type="checkbox"
                      name="is_recommended"
                      checked={editPlan.is_recommended || 0}
                      onChange={handleInputChange}
                      className="form-checkbox"
                    />
                    <span className="ml-2 text-sm text-gray-700">موصى به</span>
                  </label>
                </div>
                <div className="mb-4">
                  <label className="inline-flex gap-2 items-center">
                    <input
                      type="checkbox"
                      name="is_featured"
                      checked={editPlan.is_featured || 0}
                      onChange={handleInputChange}
                      className="form-checkbox"
                    />
                    <span className="ml-2 text-sm text-gray-700">مميزة</span>
                  </label>
                </div>
                <div className="mb-4">
                  <label className="inline-flex gap-2 items-center">
                    <input
                      type="checkbox"
                      name="is_trial"
                      checked={editPlan.is_trial || 0}
                      onChange={handleInputChange}
                      className="form-checkbox"
                    />
                    <span className="ml-2 text-sm text-gray-700">
                      تجربة مجانية
                    </span>
                  </label>
                </div>
                <div className="mb-4">
                  <label className="inline-flex gap-2 items-center">
                    <input
                      type="checkbox"
                      name="status"
                      checked={editPlan.status || 0}
                      onChange={handleInputChange}
                      className="form-checkbox"
                    />
                    <span className="ml-2 text-sm text-gray-700">
                      حالة العرض
                    </span>
                  </label>
                </div>
                {/* Dynamic Fields */}
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    مميزات الباقة:
                  </label>
                  {features.map((feature, index) => (
                    <div key={index} className="flex items-center gap-2 mb-2">
                      <input
                        type="text"
                        placeholder="اسم الميزة"
                        value={feature.name}
                        onChange={(e) =>
                          handleFeatureChange(index, "name", e.target.value)
                        }
                        className="p-2 border w-full rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                      />
                      <div className="flex gap-2 justify-start">
                        <label className="block text-sm font-medium text-gray-700">
                          تمكين:
                        </label>
                        <input
                          type="checkbox"
                          checked={feature.isEnabled}
                          onChange={(e) =>
                            handleFeatureChange(
                              index,
                              "isEnabled",
                              e.target.checked
                            )
                          }
                          className="form-checkbox ml-2"
                        />
                      </div>

                      <button
                        type="button"
                        onClick={() => removeFeature(index)}
                        className="ml-2 text-red-500"
                      >
                        <RiDeleteBin5Fill />
                      </button>
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={addFeature}
                    className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 mt-2"
                  >
                    إضافة ميزة
                  </button>
                </div>

                <div className="flex justify-start gap-2 mt-8">
                  <button
                    onClick={handleCancelEdit}
                    className="px-3 py-1 bg-red-400 text-white rounded hover:bg-red-500"
                  >
                    إلغاء
                  </button>
                  <button
                    onClick={() =>
                      handleUpdateChange({
                        editPlan,
                        setLoading,
                        setMessage,
                        fetchPlanMaster,
                        token,
                      })
                    }
                    disabled={loading}
                    className="px-3 py-1 border-gray-400 text-sm font-medium text-gray-100 ring-blue-100 bg-[#2a65e4] border border-transparent rounded-md hover:bg-[#3b64e0] focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-100"
                  >
                    {loading ? (
                      <FaSpinner size={20} className="animate-spin" />
                    ) : (
                      "تحديث"
                    )}
                  </button>
                </div>
              </div>
            ) : (
              // Display mode
              <div>
                <div className="flex justify-between">
                  <h3 className="text-2xl font-semibold text-gray-700 mb-4">
                    {plan.title}
                  </h3>
                  <div className="text-right">
                    <p className="text-sm text-gray-600">
                      تم التحديث في:{" "}
                      {new Date(plan.updated_at).toLocaleString()}
                    </p>
                  </div>
                </div>

                <p className="text-xl font-medium text-gray-700 mb-2">
                  السعر: <span className="text-green-600">${plan.price}</span>
                </p>
                <div className="grid grid-cols-2 gap-4 text-sm text-gray-600">
                <p>
                    <span className="font-bold">حالة العرض</span>{" "}
                    {plan.staus ? "نعم" : "لا"}
                  </p>
                  <p>
                    <span className="font-bold">مميز:</span>{" "}
                    {plan.isHighlighted ? "نعم" : "لا"}
                  </p>
                  <p>
                    <span className="font-bold">متميز:</span>{" "}
                    {plan.is_featured ? "نعم" : "لا"}
                  </p>
                  <p>
                    <span className="font-bold">موصى به:</span>{" "}
                    {plan.is_recommended ? "نعم" : "لا"}
                  </p>
                  <p>
                    <span className="font-bold">تجربة متاحة:</span>{" "}
                    {plan.is_trial ? "نعم" : "لا"}
                  </p>
                  <p>
                    <span className="font-bold">الحالة:</span> {plan.status}
                  </p>
                  <p>
                    <span className="font-bold">الأيام:</span> {plan.days}
                  </p>
                  <p>
                    <span className="font-bold">أيام التجربة:</span>{" "}
                    {plan.trial_days}
                  </p>
                  <p>
                    <span className="font-bold">زر طلب السعر:</span>{" "}
                    {plan.buttonText}
                  </p>
                  {/* <div>
                    <span className="font-bold">مميزات الباقة</span>
                    <p>{plan.data}</p>
                  </div> */}
                </div>
                <button
                  onClick={() => handleEdit(plan)}
                  className="mt-4 px-3 py-1 bg-yellow-500 text-white rounded hover:bg-yellow-600"
                >
                  تعديل
                </button>
              </div>
            )}
          </div>
        ))
      ) : (
        <p>No data available</p>
      )}
      {message && (
        <div
          className={`text-center mt-4 text-lg font-semibold ${
            message.type === "success" ? "text-green-500" : "text-red-500"
          }`}
        >
          {message.text}
        </div>
      )}
    </div>
  );
};

export default UpdatePlan;
