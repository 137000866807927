import React from "react";
import PricingSection from "../home/PricingSection";
import AppBar from "../home/AppBar";
import HeroComponent from "../home/HeroComponent";
import Footer from "../home/Footer";
import FeaturesSection from "../home/FeaturesSection";
import ContactUs from "../home/ContactUs";
// import MailUs from "../home/MailUs";
import { ThemeProvider } from "../context/themes/ThemeContext";
import ServicesSection from "../home/ServicesSection";

const Content: React.FC = () => {
  return (
    <ThemeProvider>
      <div className="h-screen overflow-y-scroll hide-scrollbar bg-white dark:bg-gray-950 text-black dark:text-white dark:selection:bg-blue-400 selection:bg-blue-100">
        
        <AppBar />
        <section id="home">
          <HeroComponent />
        </section>
        <section id="services" className="">
          <FeaturesSection />
        </section>
        <section id="featureservice">
          <ServicesSection />
        </section>
        <section id="pricing">
          <div className="min-h-screen flex dark:bg-gray-950 text-gray-700 dark:text-gray-300 items-center justify-center">
            <PricingSection />
          </div>
        </section>

        <section id="aboutus">
          <ContactUs />
        </section>
        <section>
          {/* <MailUs /> */}
        </section>
        <Footer />
      </div>
    </ThemeProvider>
  );
};

export default Content;
