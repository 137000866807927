import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaSignOutAlt, FaWhatsapp } from "react-icons/fa";
import { AuthContext } from "../context/AuthContext";
import { AiFillCode, AiFillFileImage } from "react-icons/ai";
import { FiFileText } from "react-icons/fi";
import { IoBagHandleOutline } from "react-icons/io5";
import { GiPresent } from "react-icons/gi";
import { MdGroups } from "react-icons/md";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { IoMdTime } from "react-icons/io";
import { MdOutlineGroupWork } from "react-icons/md";
import { AiOutlineSend } from "react-icons/ai";
import { RiPriceTag3Line } from "react-icons/ri";

interface SidebarProps {
  isOpen: boolean;
  toggleSidebar: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggleSidebar }) => {
  const location = useLocation();
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);

  const handleLogout = () => {
    logout();
    navigate("/login"); // Redirect to login page after logout
  };

  const isDashboardPath = location.pathname.startsWith("/dashboard");

  const handleDropdownToggle = (id: string) => {
    setOpenDropdown(openDropdown === id ? null : id);
  };

  return (
    <div>
      <div
        className={`absolute top-12 left-0 h-full text-gray-700 sm:bg-transparent border-r border-gray-200 py-2 transition-transform transform ${
          isOpen ? "translate-x-0 w-64 bg-white" : "sm:w-20 -translate-x-full"
        } md:translate-x-0 z-40 ease-in-out duration-300 overflow-y-auto hide-scrollbar custom-scrollbar`}
      >
        <div className="flex mx-2">
          <img
            className="h-10 mb-4 bg-cover"
            src="/logo.png"
            alt="Logo"
            loading="lazy"
          />
        </div>
        <ul className="space-y-2">
          <li className="group">
            <Link
              to="/dashboard"
              className={`flex items-center p-3 rounded-sm transition-colors duration-300 ${
                isDashboardPath
                  ? "bg-gray-200 text-gray-700"
                  : "hover:bg-slate-100 text-gray-700"
              }`}
            >
              <FaWhatsapp
                className={`w-6 h-6 mr-3 ${
                  isDashboardPath
                    ? "text-green-500"
                    : "group-hover:text-gray-700"
                }`}
              />
              <span
                className={`text-lg font-medium ${
                  isOpen ? "block" : "sm:hidden"
                }`}
              >
                أجهزة الواتس اب
              </span>
            </Link>
          </li>

          <li className="group">
            <Link
              to="/sendmessages"
              className={`flex items-center p-3 rounded-sm transition-colors duration-300 ${
                location.pathname === "/sendmessages"
                  ? "bg-gray-200 text-gray-700"
                  : "hover:bg-slate-100 text-gray-700"
              }`}
            >
              <FiFileText
                className={`w-6 h-6 mr-3 ${
                  location.pathname === "/sendmessages"
                    ? "text-green-500"
                    : "group-hover:text-gray-700"
                }`}
              />
              <span
                className={`text-lg font-medium ${
                  isOpen ? "block" : "sm:hidden"
                }`}
              >
                ارسال رسائل نص
              </span>
            </Link>
          </li>

          <li className="group">
            <Link
              to="/sendmefilessages"
              className={`flex items-center p-3 rounded-sm transition-colors duration-300 ${
                location.pathname === "/sendmefilessages"
                  ? "bg-gray-200 text-gray-700"
                  : "hover:bg-slate-100 text-gray-700"
              }`}
            >
              <AiFillFileImage
                className={`w-6 h-6 mr-3 ${
                  location.pathname === "/sendmefilessages"
                    ? "text-green-500"
                    : "group-hover:text-gray-700"
                }`}
              />
              <span
                className={`text-lg font-medium ${
                  isOpen ? "block" : "sm:hidden"
                }`}
              >
                ارسال رسائل ملفات
              </span>
            </Link>
          </li>

          {/* Groups Dropdown */}
          <li className="group">
            <button
              onClick={() => handleDropdownToggle("groups")}
              className={`flex items-center p-3 rounded-sm w-full text-left transition-colors duration-300 ${
                location.pathname === "/groupsm"
                  ? "bg-gray-100 text-gray-700"
                  : "hover:bg-slate-100 text-gray-700"
              }`}
            >
              <MdGroups
                className={`w-6 h-6 mr-3 ${
                  location.pathname === "/groupsm"
                    ? "text-green-500"
                    : "group-hover:text-gray-700"
                }`}
              />
              <span
                className={`text-lg font-medium ${
                  isOpen ? "block" : "sm:hidden"
                }`}
              >
                ارسال للمجموعات
              </span>
              {openDropdown === "groups" ? (
                <IoIosArrowDown className="ml-auto font-bold" />
              ) : (
                <IoIosArrowForward className="ml-auto font-bold" />
              )}
            </button>
            <ul
              className={`pl-6 space-y-2 transition-all duration-300 ease-in-out ${
                openDropdown === "groups"
                  ? "max-h-screen opacity-100"
                  : "max-h-0 hidden opacity-0"
              }`}
            >
              <li>
                <Link
                  to="/groupsm"
                  className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                    location.pathname === "/groupsm"
                      ? `${
                          isOpen
                            ? "sm:bg-blue-50 border-l-2 border-blue-500 text-gray-700"
                            : "sm:bg-blue-50 rounded-lg"
                        }`
                      : "hover:bg-slate-100 text-gray-700"
                  }`}
                >
                  <AiOutlineSend
                    className={`${isOpen ? "w-6 h-6" : "w-6 h-6"} ${
                      location.pathname === "/groupsm"
                        ? "text-green-500"
                        : "group-hover:text-gray-700"
                    }`}
                  />
                  <span
                    className={`text-sm font-medium ${
                      isOpen ? "block" : "sm:hidden"
                    }`}
                  >
                    ارسال رسائل نص للمجموعات
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/groupsendf"
                  className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                    location.pathname === "/groupsendf"
                      ? `${
                          isOpen
                            ? "sm:bg-blue-50 border-l-2 border-blue-500 text-gray-700"
                            : "sm:bg-blue-50 rounded-lg"
                        }`
                      : "sm:hover:bg-slate-100 text-gray-700"
                  }`}
                >
                  <MdOutlineGroupWork
                    className={`${isOpen ? "w-6 h-6" : "w-6 h-6"} ${
                      location.pathname === "/groupsendf"
                        ? "text-green-500"
                        : "group-hover:text-gray-700"
                    }`}
                  />
                  <span
                    className={`text-sm font-medium ${
                      isOpen ? "block" : "sm:hidden"
                    }`}
                  >
                    ارسال ملفات للمجموعات
                  </span>
                </Link>
              </li>
              {/* Add more group links here */}
            </ul>
          </li>

          {/* <li className="group">
            <Link
              to="/sendcampaign"
              className={`flex items-center p-3 rounded-sm transition-colors duration-300 ${
                location.pathname === "/sendcampaign"
                  ? "bg-gray-200 text-gray-700"
                  : "hover:bg-slate-100 text-gray-700"
              }`}
            >
              <IoMdTime
                className={`w-6 h-6 mr-3 ${
                  location.pathname === "/sendcampaign"
                    ? "text-green-500"
                    : "group-hover:text-gray-700"
                }`}
              />
              <span
                className={`text-lg font-medium ${
                  isOpen ? "block" : "sm:hidden"
                }`}
              >
                ارسال رسائل تذكيرية
              </span>
            </Link>
          </li> */}
          
          {/* pricing */}
          <li className="group">
            <button
              onClick={() => handleDropdownToggle("groupsp")}
              className={`flex items-center p-3 rounded-sm w-full text-left transition-colors duration-300 ${
                location.pathname === "/GiPresent"
                  ? "bg-gray-100 text-gray-700"
                  : "hover:bg-slate-100 text-gray-700"
              }`}
            >
              <RiPriceTag3Line
                className={`w-6 h-6 mr-3 ${
                  location.pathname === "/GiPresent"
                    ? "text-green-500"
                    : "group-hover:text-gray-700"
                }`}
              />
              <span
                className={`text-lg font-medium ${
                  isOpen ? "block" : "sm:hidden"
                }`}
              >
                الاسعار
              </span>
              {openDropdown === "groupsp" ? (
                <IoIosArrowDown className="ml-auto font-bold" />
              ) : (
                <IoIosArrowForward className="ml-auto font-bold" />
              )}
            </button>
            <ul
              className={`pl-6 space-y-2 transition-all duration-300 ease-in-out ${
                openDropdown === "groupsp"
                  ? "max-h-screen opacity-100"
                  : "max-h-0 hidden opacity-0"
              }`}
            >
              <li>
                <Link
                  to="/promotions"
                  className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                    location.pathname === "/promotions"
                      ? `${
                          isOpen
                            ? "sm:bg-blue-50 border-l-2 border-blue-500 text-gray-700"
                            : "sm:bg-blue-50 rounded-lg"
                        }`
                      : "hover:bg-slate-100 text-gray-700"
                  }`}
                >
                  <GiPresent
                    className={`${isOpen ? "w-6 h-6" : "w-6 h-6"} ${
                      location.pathname === "/promotions"
                        ? "text-green-500"
                        : "group-hover:text-gray-700"
                    }`}
                  />
                  <span
                    className={`text-sm font-medium ${
                      isOpen ? "block" : "sm:hidden"
                    }`}
                  >
                    الباقات
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/orders"
                  className={`flex items-center m-2 p-2 transition-colors duration-300 ${
                    location.pathname === "/orders"
                      ? `${
                          isOpen
                            ? "sm:bg-blue-50 border-l-2 border-blue-500 text-gray-700"
                            : "sm:bg-blue-50 rounded-lg"
                        }`
                      : "sm:hover:bg-slate-100 text-gray-700"
                  }`}
                >
                  <IoBagHandleOutline
                    className={`${isOpen ? "w-6 h-6" : "w-6 h-6"} ${
                      location.pathname === "/orders"
                        ? "text-green-500"
                        : "group-hover:text-gray-700"
                    }`}
                  />
                  <span
                    className={`text-sm font-medium ${
                      isOpen ? "block" : "sm:hidden"
                    }`}
                  >
                    الطلبات
                  </span>
                </Link>
              </li>
              {/* Add more group links here */}
            </ul>
          </li>
          {/* pricing */}

          <li className="group">
            <a
              href="https://documenter.getpostman.com/view/18114527/2sA3QwbVPz"
              target="_blank"
              rel="noopener noreferrer"
              className={`flex items-center p-3 rounded-sm transition-colors duration-300 ${
                location.pathname ===
                "https://documenter.getpostman.com/view/18114527/2sA3QwbVPz"
                  ? "bg-gray-200 text-gray-700"
                  : "hover:bg-slate-100 text-gray-700"
              }`}
            >
              <AiFillCode className="w-6 h-6 mr-3" />
              <span
                className={`text-lg font-medium ${
                  isOpen ? "block" : "sm:hidden"
                }`}
              >
                API Docs
              </span>
            </a>
          </li>
          <li className="group">
            <button
              onClick={() => {
                toggleSidebar();
                handleLogout();
              }}
              className={`flex items-center p-3 w-full text-left rounded-sm transition-colors duration-300 ${
                location.pathname === "/logout"
                  ? "bg-gray-200 text-gray-700"
                  : "hover:bg-slate-100 text-gray-700"
              }`}
            >
              <FaSignOutAlt
                className={`w-6 h-6 mr-3 ${
                  location.pathname === "/logout"
                    ? "text-gray-700"
                    : "group-hover:text-gray-700"
                }`}
              />
              <span
                className={`text-lg font-medium ${
                  isOpen ? "block" : "sm:hidden"
                }`}
              >
                تسجيل الخروج
              </span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
