// src/components/CompaignComponent.tsx
import React from "react";
import { AiFillFileText } from "react-icons/ai";
import RichTextEditorGroup from "./CompaignMessage";

const CompaignComponent: React.FC = () => {

  return (
    <div className="border-b border-gray-300 justify-end  gap-3container rounded-md mt-14">
        <div className="flex justify-end mx-16 text-lg mt-8">
        <span>ارسال رسائل مجدولة</span>
        <AiFillFileText size={25} className="text-cyan-600"/>
        </div>

      {/* <RichTextEditorGroup /> */}
    </div>
  );
};

export default CompaignComponent;
