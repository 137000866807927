// src/AuthContext.tsx
import React, { createContext, useState, ReactNode } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
// import { randomBytes } from "crypto";

interface AuthContextType {
  isAuthenticated: boolean;
  userId: number | null;
  token: string | null;
  rolePrivileges: number | null;
  username: string | null;
  usernamee: string | null;
  login: (
    username: string,
    password: string,
    navigate: ReturnType<typeof useNavigate>
  ) => Promise<void>;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  rolePrivileges: null,
  userId:null,
  token: null,
  username: null,
  usernamee: null,
  login: async () => {},
  logout: () => {},
});

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [token, setToken] = useState<string | null>(
    localStorage.getItem("token")
  );
  const secretKey = "yJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9";
  
  // console.log("Generated Secret Key:", secretKey);

  const [username, setUsername] = useState<string | null>(null);
  // const [rolePrivileges, setRolePrivileges] = useState<number | null>(localStorage.getItem("rolePrevleg"));
  const decryptRolePrivileges = (
    encryptedData: string,
    secretKey: string
  ): number | null => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      return parseInt(decryptedData, 10);
    } catch (error) {
      console.error("Decryption failed:", error);
      return null;
    }
  };

  // Initialize the rolePrivileges state with decryption
  const [rolePrivileges, setRolePrivileges] = useState<number | null>(() => {
    const storedValue = localStorage.getItem("rolePrevleg");
    return storedValue !== null
      ? decryptRolePrivileges(storedValue, secretKey)
      : null;
  });
  const [userId, setUserId] = useState<number | null>(() => {
    const storedValue = localStorage.getItem("userid");
    return storedValue !== null
      ? decryptRolePrivileges(storedValue, secretKey)
      : null;
  });
  const [usernamee, setUsernamee] = useState<string | null>(
    localStorage.getItem("usernamee")
  );

  // console.log(rolePrivileges)

  const login = async (
    username: string,
    password: string,
    navigate: ReturnType<typeof useNavigate>
  ) => {
    try {
      const formData = new FormData();
      formData.append("username", username);
      formData.append("password", password);
      const response = await axios.post(
        "https://whats1.sky.sky4system.com/api/user/login",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // console.log(response.data)
      
      const token = response.data.user.token;
      const rolePrevleg: number = response.data.user.id_cms_privileges;
      const userId: number = response.data.user.id;
      const usernamee = response.data.user.username;
      const encryptedData = CryptoJS.AES.encrypt(
        rolePrevleg.toString(),
        secretKey
      ).toString();
      const encryptedDataUId = CryptoJS.AES.encrypt(
        userId.toString(),
        secretKey
      ).toString();

      setToken(token);
      setUsername(usernamee);
      setRolePrivileges(rolePrevleg);
      setUserId(userId);
      setUsernamee(usernamee);

      // console.log(userId)

      localStorage.setItem("token", token);
      localStorage.setItem("userid", encryptedDataUId);
      localStorage.setItem("rolePrevleg", encryptedData);
      localStorage.setItem("usernamee", usernamee);

      // Store token in an HttpOnly cookie

      // Cookies.set("token", token, { httpOnly: true, secure: true });
      // Cookies.set("rolePrevleg", rolePrevleg, { httpOnly: true, secure: true });
      // Cookies.set("usernamee", usernamee, { secure: true });

      navigate("/dashboard");
    } catch (error) {
      throw new Error("Invalid credentials");
    }
  };

  const logout = () => {
    setToken(null);
    setUsername(null);
    setRolePrivileges(null);
    setUsernamee(null);

    // Clear cookies
    localStorage.removeItem("token");
    localStorage.removeItem("usernamee");
    localStorage.removeItem("rolePrevleg");    
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!token,
        rolePrivileges,
        userId,
        token,
        username,
        usernamee,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
