import React, { useState, useEffect, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import { DeviceMasterContext } from "../../../../context/devices/DeviceMasterContext";
import { AuthContext } from "../../../../context/AuthContext";
import axios from "axios";
import TimelineSlider from "../settings/TimeIndicator"; // Correct the import path if necessary
import { FaClock } from "react-icons/fa";
import { Device } from "../../../../interfaces/Device";

const TimeLineQueue: React.FC = () => {
  const { deviceId } = useParams<{ deviceId: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [deviceDetails, setDeviceDetails] = useState<Device | undefined>(undefined);
  const [newTimeDelay, setNewTimeDelay] = useState<number>(0);
  const [message, setMessage] = useState<{ text: string; type: "success" | "error" } | null>(null);

  const { deviceData, fetchDeviceMaster } = useContext(DeviceMasterContext);
  const { token } = useContext(AuthContext);

  const fetchDeviceDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      if (deviceData && deviceData.data) {
        const foundDevice = deviceData.data.find(
          (device: Device) => device.deviceId === deviceId
        );
        if (foundDevice) {
          setDeviceDetails(foundDevice);
          setNewTimeDelay(foundDevice.Delay_time / 1000);
        } else {
          console.warn("Device not found in deviceData.");
        }
      } else {
        console.warn("deviceData or deviceData.data is undefined.");
      }
    } catch (error) {
      console.log("Error fetching device details:", error);
    } finally {
      setIsLoading(false);
    }
  }, [deviceId, deviceData]);

  useEffect(() => {
    fetchDeviceDetails();
  }, [fetchDeviceDetails]);

  const handleUpdateClick = async () => {
    if (deviceDetails) {
      setIsLoading(true);
      setMessage(null);

      const formData = new FormData();
      formData.append("delay_time", (newTimeDelay * 1000).toString()); // Convert seconds to milliseconds for server
      formData.append("deviceToken", deviceDetails.Token);
      formData.append("deviceId", deviceDetails.deviceId);

      try {
        const response = await axios.post(
          "https://whats1.sky.sky4system.com/api/user/updateDevice-DelayTime",
          formData,
          {
            headers: {
              "auth-token": token,
            },
          }
        );
        // console.log(response.data);
        if (response.data.status === true) {
          setNewTimeDelay(response.data.data.Delay_time / 1000);
          fetchDeviceMaster();
          setMessage({
            text: "تم تحديث الفارق الزمني بين الرسائل",
            type: "success",
          });
        } else {
          setMessage({
            text: "فشل التحديث",
            type: "error",
          });
        }
        await fetchDeviceDetails();
      } catch (error) {
        console.log("Error updating webhook URL:", error);
        setMessage({
          text: "حصل خطأ",
          type: "error",
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (message) {
      timeout = setTimeout(() => setMessage(null), 3000); // Clear message after 3 seconds
    }
    return () => clearTimeout(timeout);
  }, [message]);

  return (
    <div className="px-6 justify-start">
      {message && (
        <div
          className={`p-4 mb-2 text-sm ${message.type === "success" ? "bg-green-200" : "bg-red-200"} rounded-lg`}
        >
          {message.text}
        </div>
      )}
      {deviceDetails && (
        <>
          <TimelineSlider
            events={[
              {
                timestamp: deviceDetails.Delay_time / 1000, // Convert milliseconds to seconds for display
                label: "1 Sec",
                description: "الفارق الزمني بين الرسائل بالثواني:",
                icon: <FaClock />,
              },
            ]}
            currentTime={newTimeDelay} // Current time in seconds
            onTimeChange={(time) => setNewTimeDelay(time)} // Time change in seconds
          />
          <div className="flex justify-end">
            <button
              onClick={handleUpdateClick}
              disabled={isLoading}
              className={`inline-flex justify-end gap-2 px-2 py-2 mb-10 text-sm font-medium border-gray-400 text-gray-100 bg-blue-500 border border-transparent rounded-md hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-100 ${
                isLoading ? "cursor-not-allowed opacity-50" : ""
              }`}
            >
              <span>{isLoading ? "تأكيد" : "تأكيد"}</span>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default TimeLineQueue;
