import React, { createContext, useContext, useState, useEffect, ReactNode, useCallback } from "react";
import axios from "axios";
import { AuthContext } from "./AuthContext";

interface UserMasterContextType {
  userData: any | null;
  fetchUserMaster: () => void;
}

export const UserMasterContext = createContext<UserMasterContextType>({
  userData: null,
  fetchUserMaster: () => {},
});

const UserMasterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { token } = useContext(AuthContext);
  const [userData, setUserData] = useState<any | null>(null);

  // Fetch the latest device master data
  const fetchUserMaster = useCallback(async () => {
    if (!token) return;
    try {
      const response = await axios.post(
        "https://whats1.sky.sky4system.com/api/user/getmasteruser",
        new FormData(),
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          params: {
            token,
          },
        }
      );
      setUserData(response.data);
      // console.log(response.data.data)
    } catch (error) {
      console.error("Failed to fetch user master data", error);
    }
  }, [token]);

  useEffect(() => {
    fetchUserMaster();
  }, [fetchUserMaster]);

  return (
    <UserMasterContext.Provider value={{ userData, fetchUserMaster }}>
      {children}
    </UserMasterContext.Provider>
  );
};

export default UserMasterProvider;
