import React, { useState, useEffect } from 'react';

interface SwitchToggleProps {
  initialChecked?: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
}

const SwitchToggle: React.FC<SwitchToggleProps> = ({ initialChecked, onChange, disabled = false }) => {
  const [isChecked, setIsChecked] = useState<boolean>(initialChecked ?? false);

  useEffect(() => {
    if (initialChecked !== undefined) {
      setIsChecked(initialChecked);
    }
  }, [initialChecked]);

  const handleToggle = () => {
    if (disabled) return;

    const newChecked = !isChecked;
    setIsChecked(newChecked);
    if (onChange) {
      onChange(newChecked);
    }
  };

  return (
    <div
      className={`relative inline-flex items-center cursor-pointer select-none transition-colors duration-300 ease-in-out ${
        isChecked ? 'bg-green-500' : 'bg-gray-300'
      } ${disabled ? 'cursor-not-allowed opacity-50' : ''} rounded-full w-12 h-6`}
      onClick={handleToggle}
    >
      <span
        className={`absolute left-0 top-0 block w-6 h-6 rounded-full bg-white shadow-md transform transition-transform duration-300 ease-in-out ${
          isChecked ? 'translate-x-6' : 'translate-x-0'
        }`}
      />
      {disabled && (
        <div className="absolute inset-0 flex items-center justify-center">
          <svg
            className="w-4 h-4 text-white animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
            ></path>
          </svg>
        </div>
      )}
    </div>
  );
};

export default SwitchToggle;
