import React, { createContext, useContext, useState, useEffect, ReactNode, useCallback } from "react";
import axios from "axios";
import { AuthContext } from "../AuthContext";

interface DevicePeriodContextCotextType {
  peroidData: any | null;
  isloading: boolean;
  fetchDeviceMaster: () => void;
}

export const DevicePeriodContextCotext = createContext<DevicePeriodContextCotextType>({
  peroidData: null,
  isloading: false,
  fetchDeviceMaster: () => {},
});

const DevicePeriodContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { token } = useContext(AuthContext);
  const [peroidData, setPeriodData] = useState<any | null>(null);
  const [isloading, setIsLoading] = useState<boolean>(false);

  // Fetch the latest device master data
  const fetchDeviceMaster = useCallback(async () => {
    if (!token) return;
    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://whats1.sky.sky4system.com/api/user/GetProideType",
        new FormData(),
        {
          headers: {
            'auth-token': token,
          },
          params: {
            token,
          },
        }
      );
      // console.log(response.data)
      setPeriodData(response.data);
    } catch (error) {
      console.log("Failed to fetch periodTypeData data", error);
    }
    finally {
      setIsLoading(false); 
    }
  }, [token]);

  useEffect(() => {
    fetchDeviceMaster();
  }, [fetchDeviceMaster]);

  return (
    <DevicePeriodContextCotext.Provider value={{ peroidData,isloading, fetchDeviceMaster }}>
      {children}
    </DevicePeriodContextCotext.Provider>
  );
};

export default DevicePeriodContextProvider;
