import React, { createContext, useContext, useState, useEffect, ReactNode, useCallback } from "react";
import axios from "axios";
import { AuthContext } from "../AuthContext";

interface PlanContextType {
  planData: any | null;
  isloading: boolean;
  fetchPlanMaster: () => void;
}

export const PlanContext = createContext<PlanContextType>({
  planData: null,
  isloading: false,
  fetchPlanMaster: () => {},
});

const PlanContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { token } = useContext(AuthContext);
  const [planData, setPlanData] = useState<any | null>(null);
  const [isloading, setIsLoading] = useState<boolean>(false);

  const fetchPlanMaster = useCallback(async () => {
    if (!token) return;
    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://whats1.sky.sky4system.com/api/user/GeTPlanData",
        new FormData(),
        {
          headers: {
            'auth-token': token,
          },
          params: {
            token,
          },
        }
      );
      // console.log(response.data)

      setPlanData(response.data);
    } catch (error) {
      console.log("Failed to fetch planData data", error);
    }
    finally {
      setIsLoading(false); 
    }
  }, [token]);

  useEffect(() => {
    fetchPlanMaster();
  }, [fetchPlanMaster]);

  return (
    <PlanContext.Provider value={{ planData,isloading, fetchPlanMaster }}>
      {children}
    </PlanContext.Provider>
  );
};

export default PlanContextProvider;
