import React, { useEffect, useState } from "react";
import axios from "axios";
import DeviceSelect from "./data/DeviceSelect";
import { FaRegWindowClose } from "react-icons/fa";
import { FiRefreshCcw } from "react-icons/fi";
import GroupsSelect from "./data/GroupsSelect";

interface SendResult {
  number: string;
  status: "success" | "error";
  message: string;
  isLoadingr?: boolean;
}

const RichTextEditorGroup: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [sendResults, setSendResults] = useState<SendResult[]>([]);
  const [groupError, setGroupError] = useState<string | null>(null);
  const [messageError, setMessageError] = useState<string | null>(null);
  const [deviceError, setDeviceError] = useState<string | null>(null);
  const [selectedDevice, setSelectedDevice] = useState<any>(null);
  const [selectedGroup, setSelectedGroup] = useState<any>(null);
  const [successSend, setSuccessSend] = useState<{
    text: string;
    type: "success" | "error";
  } | null>(null);

  const handleSendBulkMessage = async () => {
    resetErrors();
    if (!validateInputs()) return;

    setIsLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));

    try {
      if (!selectedGroup || selectedGroup.length === 0) {
        throw new Error("No groups selected");
      }

      // Send messages to all selected groups
      const sendResults = await Promise.all(
        selectedGroup.map(async (id: string) => {
          return await sendMessage(id);
        })
      );

      setSendResults(sendResults);
    } catch (error) {
      console.log("Error updating send message:", error);
      setSendResults([
        { number: "", status: "error", message: "حدث خطأ أثناء إرسال الرسالة" },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResend = async (number: string, index: number) => {
    setSendResults((prevResults) =>
      prevResults.map((result, i) =>
        i === index ? { ...result, isLoadingr: true, message: "" } : result
      )
    );
    const response = await sendMessage(number);
    setSendResults((prevResults) =>
      prevResults.map((result, i) =>
        i === index
          ? {
              ...result,
              isLoadingr: false,
              status: response.status,
              message: response.message,
            }
          : result
      )
    );
  };

  const clearMessages = () => setSendResults([]);

  const resetErrors = () => {
    setGroupError(null);
    setMessageError(null);
    setDeviceError(null);
    setSendResults([]);
  };

  const validateInputs = (): boolean => {
    if (!selectedDevice) {
      setDeviceError("يجب تحديد جهاز.");
      return false;
    }
    if (!selectedGroup || selectedGroup.length === 0) {
      setGroupError("يجب تحديد مجموعة واتس اب.");
      return false;
    }
    if (message.trim() === "") {
      setMessageError("يجب إدخال رسالة.");
      return false;
    }
    return true;
  };

  const sendMessage = async (number: string): Promise<SendResult> => {
    const delay = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    await delay(1000);

    const formData = new FormData();
    formData.append("deviceToken", selectedDevice.Token);
    formData.append("deviceId", selectedDevice.deviceId);
    formData.append("message", `${message}`);
    formData.append("Groupid", number);

    try {
      const response = await axios.post(
        "https://whats1.sky.sky4system.com/api/group/create-text",
        formData
      );
      // console.log(response.data)
      // console.log(number)
      const result = getSendResult(response.data, number);
      setSuccessSend((await result).successSend);
      return (await result).sendResult;
    } catch (error) {
      console.error("Error sending message:", error);
      return { number, status: "error", message: "فشل في إرسال الرسالة" };
    }
  };

  const getSendResult = async (data: any, number: string) => {
    let result: SendResult;
    let successSend: { text: string; type: "success" | "error" };

    if (data.status) {
      result = {
        number,
        status: "success",
        message: `تمت عملية الإرسال`,
      };
      successSend = {
        text: `تمت عملية الإرسال`,
        type: "success",
      };
    } else if (data.errNum === "403") {
      result = {
        number,
        status: "error",
        message: `سيتم إيقاف خدمة هذا الجهاز حتى يتم تجديد الاشتراك`,
      };
      successSend = {
        text: `سيتم إيقاف خدمة هذا الجهاز حتى يتم تجديد الاشتراك`,
        type: "error",
      };
    } else if (data.errNum === "401") {
      result = {
        number,
        status: "error",
        message: `المشرفين فقط يمكنهم الارسال`,
      };
      successSend = { text: `المشرفين فقط يمكنهم الارسال`, type: "error" };
    } else {
      result = { number, status: "error", message: `فشل في إرسال الرسالة` };
      successSend = { text: `فشل في الإرسال إلى`, type: "error" };
    }
    setSendResults((prevResults) => {
      const existingResultIndex = prevResults.findIndex(
        (r) => r.number === number
      );

      if (existingResultIndex !== -1) {
        return prevResults.map((r, i) =>
          i === existingResultIndex ? { ...r, ...result } : r
        );
      } else {
        return [...prevResults, result];
      }
    });

    return { sendResult: result, successSend };
  };

  useEffect(() => {
    if (successSend) {
      const timeout = setTimeout(() => setSuccessSend(null), 3000);
      return () => clearTimeout(timeout);
    }
  }, [successSend]);

  return (
    <div className="mt-8 mx-2 flex gap-4 overflow-y-scroll hide-scrollbar">
      {sendResults.length > 0 && (
        <div>
          <div
            className="absolute top-16 right-8 w-auto p-4 bg-gray-700 text-white overflow-y-scroll text-sm custom-scrollbar rounded-lg shadow-lg z-50 opacity-75"
            style={{ maxHeight: "30rem" }}
          >
            {" "}
            <button onClick={clearMessages} className="cursor-pointer">
              <FaRegWindowClose className="p-1 text-4xl text-red-400" />
            </button>
            <ul>
              {sendResults.map((result, index) => (
                <li key={index} className="mb-2 flex items-center">
                  <span className="mr-2">{result.message}</span>
                  {result.status === "error" && (
                    <>
                      <FiRefreshCcw
                        onClick={() => handleResend(result.number, index)}
                        className={`ml-2 cursor-pointer ${
                          result.isLoadingr ? "animate-spin" : ""
                        }`}
                      />
                    </>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      <div className="sm:w-10/12 mx-auto w-full">
        <div className="mb-6">
          <DeviceSelect onDeviceSelect={setSelectedDevice} />
          {deviceError && <p className="text-red-500 text-sm">{deviceError}</p>}
        </div>

        <div className="mb-4">
          <GroupsSelect onGroupsSelect={setSelectedGroup} />
          {groupError && <div className="text-red-500">{groupError}</div>}
        </div>

        <div className="mb-6">
          <textarea
            rows={7}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="mt-8 w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:border-cyan-500"
            placeholder="...اكتب رسالتك"
          />
          {messageError && <div className="text-red-500">{messageError}</div>}
        </div>

        <div>
          <button
            onClick={handleSendBulkMessage}
            className="px-4 py-1 mb-4 bg-blue-500 text-white rounded hover:bg-blue-600 transitiondisabled:opacity-50"
            disabled={isLoading}
          >
            {isLoading ? "جاري الإرسال..." : "إرسال"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RichTextEditorGroup;
