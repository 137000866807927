import React, { useContext, useMemo, useState } from "react";
import Select from "react-select";
import { DevicePeriodContextCotext } from '../../../../context/devices/DevicePeriodContext';

interface OptionType {
  value: string;
  label: string;
  orderDetials: any;
}

interface PeroidSelectOrderProps {
  onPeroidSelectOrder: (orderDetials: any) => void;
}

const PeroidSelectOrder: React.FC<PeroidSelectOrderProps> = ({ onPeroidSelectOrder }) => {
  const { peroidData } = useContext(DevicePeriodContextCotext);
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);

  const options = useMemo(() => {
    if (!peroidData || !peroidData.data) return [];

    return peroidData.data.map((peroid: any) => ({
      value: peroid.id,
      label: peroid.name,
      orderDetials: peroid,
    }));
  }, [peroidData]);

  const handleChange = (selected: OptionType | null) => {
    setSelectedOption(selected);
    if (selected) {
      onPeroidSelectOrder(selected.orderDetials);
    } else {
      onPeroidSelectOrder(null);
    }
  };

  return (
    <div className="mt-2">
      <Select
        options={options}
        className="border border-blue-500 appearance-none focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100 rounded"
        onChange={handleChange}
        value={selectedOption}
        placeholder="تحديد مدة الاشتراك"
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? 'grey' : 'red',
          }),
        }}
      />
    </div>
  );
};

export default PeroidSelectOrder;
