import React from 'react';
import PricingComponent from './PricingComponent';

const PricingSection: React.FC = () => {
  return (
    <div> 
      <h2 className="text-3xl font-bold mt-4 text-center">
          الأسعار
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-4 justify-center items-center px-4 my-4">
      <PricingComponent
          price="$10"
          plan="الأساسية"
          description=".تشمل جميع الأساسيات للشركات التي بدأت للتو"
          features={[
            { name: 'API Access', isEnabled: true },
            { name: 'عدد الرسائل غير محدود', isEnabled: true },
            { name: 'الرسائل النصية', isEnabled: true },
            { name: 'رسائل صور وملفات', isEnabled: false },
            { name: 'الرد الآلي', isEnabled: false },
            { name: 'رسائل المجموعات', isEnabled: false },
            { name: 'رسائل فيديو', isEnabled: false },
          ]}
          buttonText="اطلب الان" linkTo={'dashboard'}      />
      <PricingComponent
          price="$13"
          plan="المتقدمة"
          description=".أفضل للشركات المتنامية التي تريد المزيد من العملاء"
          features={[
            { name: 'API Access', isEnabled: true },
            { name: 'عدد الرسائل غير محدود', isEnabled: true },
            { name: 'الرسائل النصية', isEnabled: true },
            { name: 'رسائل صور وملفات', isEnabled: false },
            { name: 'الرد الآلي', isEnabled: true },
            { name: 'رسائل المجموعات', isEnabled: true },
            { name: 'رسائل فيديو', isEnabled: false },
          ]}
          buttonText="اطلب الان"
          isHighlighted={true} linkTo={'dashboard'}      />
      <PricingComponent
          price="$15"
          plan="الشاملة"
          description=".ميزات متقدمة للمحترفين الذين يحتاجون إلى مزيد من التخصيص"
          features={[
            { name: 'API Access', isEnabled: true },
            { name: 'عدد الرسائل غير محدود', isEnabled: true },
            { name: 'الرسائل النصية', isEnabled: true },
            { name: 'رسائل صور وملفات', isEnabled: true },
            { name: 'الرد الآلي', isEnabled: true },
            { name: 'رسائل المجموعات', isEnabled: true },
            { name: 'رسائل فيديو', isEnabled: true },
          ]}
          buttonText="اطلب الان" linkTo={'dashboard'}      />
    </div></div>
   
  );
};

export default PricingSection;
