import React, { useState } from 'react';
import CreatDailgDevice from '../../../components/devices/CreateDailgDevice';
import { FiPlus } from 'react-icons/fi';

const ParentCeatDevice: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="flex items-center justify-center">
      <button
        className="px-4 py-2 text-white bg-[#20b8ff] rounded-md hover:bg-[#2cbbfc]"
        onClick={openModal}
      >
        <FiPlus size={25}/>
      </button>

      <CreatDailgDevice isOpen={isModalOpen} closeModal={closeModal} />
    </div>
  );
};

export default ParentCeatDevice;
