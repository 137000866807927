import React from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaHeart } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { BsTwitterX } from "react-icons/bs";
import { useTheme } from "../context/themes/ThemeContext";

const Footer: React.FC = () => {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const navigateToSection = (section: string) => {
    navigate(`/?v=${section}`);
    document.getElementById(section)?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <footer className="bg-white text-gray-700 dark:text-gray-100 dark:bg-gray-950 py-8">
      <div className=" px-6 sm:px-16 lg:px-16">
        <div
          className="max-w-6xl mx-auto"
          dir="rtl"
        >
          <div className="lg:flex lg:justify-between lg:items-start lg:space-x-12 space-y-8 lg:space-y-0">
            <div className="lg:w-1/3">
              <div className="mb-8 lg:mb-0">
                <div className="flex justify-center lg:justify-start mb-6">
                  <img
                    className="h-28 w-28 object-contain"
                    src={theme === "dark" ? "/logow.png" : "/logo.png"}
                    alt="Logo"
                    loading="lazy"
                  />
                </div>
                <div className="flex justify-center lg:justify-start gap-4">
                  <a
                    href="https://www.instagram.com/sky4system/?utm_source=qr&igsh=MW0zYTh0cWtlYXpqaw%3D%3Dsky4system/?utm_source=qr&igsh=MW0zYTh0cWtlYXpqaw%3D%3D/alawael.for.info?mibextid=ZbWKwL"
                    className="hover:text-[#3a74ba] transition-colors duration-300"
                  >
                    <FaFacebook size={20} />
                  </a>
                  <a
                    href="https://www.x.com/"
                    className="hover:text-[#3a74ba] transition-colors duration-300"
                  >
                    <BsTwitterX size={20} />
                  </a>
                  <a
                    href="https://www.instagram.com/sky4system/?utm_source=qr&igsh=MW0zYTh0cWtlYXpqaw%3D%3D"
                    className="hover:text-[#3a74ba] transition-colors duration-300"
                  >
                    <FaInstagram size={20} />
                  </a>
                  <a
                    href="https://www.linkedin.com/"
                    className="hover:text-[#3a74ba] transition-colors duration-300"
                  >
                    <FaLinkedin size={20} />
                  </a>
                </div>
              </div>
            </div>

            <div className="lg:w-1/3">
              <div className="mb-8 lg:mb-0">
                <h2 className="text-xl font-semibold mb-4 text-center lg:text-left">
                  تواصل معنا
                </h2>
                <address className="space-y-2 text-center lg:text-left">
                  <p>اليمن صنعاء</p>
                  <p>شارع المقالح - أمام بهارات بن ياسين</p>
                  <p>78008819 - 016886922</p>
                  <a
                    href="mailto:Info@Sky4system.Com"
                    className="text-gray-700 dark:text-gray-100 hover:underline"
                  >
                    info@sky.com
                  </a>
                </address>
              </div>
            </div>

            <div className="lg:w-1/3">
              <div className="mb-8 lg:mb-0">
                <h2 className="text-xl font-semibold mb-4 text-center lg:text-left">
                  روابط
                </h2>
                <ul className="space-y-3 text-center lg:text-left">
                  <li>
                    <span
                      onClick={() => navigateToSection("home")}
                      className="hover:text-[#3a74ba] transition-colors duration-300 cursor-pointer"
                    >
                      الرئيسية
                    </span>
                  </li>
                  <li>
                    <span
                      onClick={() => navigateToSection("pricing")}
                      className="hover:text-[#3a74ba] transition-colors duration-300 cursor-pointer"
                    >
                      الأسعار
                    </span>
                  </li>
                  <li>
                    <span
                      onClick={() => navigateToSection("services")}
                      className="hover:text-[#3a74ba] transition-colors duration-300 cursor-pointer"
                    >
                      الخدمات
                    </span>
                  </li>
                  <li>
                    <a
                      href="https://documenter.getpostman.com/view/18114527/2sA3QwbVPz"
                      className="hover:text-[#3a74ba] transition-colors duration-300"
                    >
                      APIs
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="border-t sm:px-16 lg:px-16 dark:border-slate-600 py-4 mt-8"></div>
        {/* Footer Bottom */}
        <div className="">
          <p className="text-sm">
            <span className="flex gap-1 justify-center">
              © 2024 Powered By <FaHeart color="#f59e0b" />{" "}
              <a
                href="https://skysystem.dev/en"
                className="dark:text-amber-400 text-blue-600 hover:underline"
              >
                SkySystems
              </a>{" "}
              All rights reserved.
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
