import React, { createContext, ReactNode, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import { DeviceMasterContext } from "./DeviceMasterContext";

interface CreateDeviceContextType {
  createdivec: (
    number: string,
    name: string,
    navigate: ReturnType<typeof useNavigate>
  ) => Promise<any>;
}

export const CreateDeviceContext = createContext<CreateDeviceContextType>({
  createdivec: async () => {},
});

const generateDeviceId = (): string => {
  return `${Math.random().toString(36).substr(2, 9).toUpperCase()}`;
};

const CreateDeviceProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { token } = useContext(AuthContext);
  const { fetchDeviceMaster } = useContext(DeviceMasterContext);

  const createdivec = async (
    number: string,
    name: string,
    navigate: ReturnType<typeof useNavigate>
  ) => {
    try {
      if (!token) {
        console.error("Token is missing");
        return { status: false, message: "Token is missing" };
      }

      const deviceId = generateDeviceId();
      const formData = new FormData();
      formData.append("webhook_url", "");
      formData.append("number", number);
      formData.append("name", name);
      formData.append("deviceId", deviceId);
      formData.append("description", "description");
      formData.append("plan_id", "1");
      formData.append("Periode_id", "1");

      const response = await axios.post(
        "https://whats1.sky.sky4system.com/api/user/CreateDevice",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "auth-token": token,
          },
        }
      );

      // console.log("Response:", response.data);

      if (response.data.status === true) {
        // Call fetchDeviceMaster to update device data
        fetchDeviceMaster();
        navigate("/dashboard");
      }

      return response.data;
    } catch (error) {
      console.error("Error:", error);
      return { status: false, message: "خظأ الاتصال بالانترت" };
    }
  };

  return (
    <CreateDeviceContext.Provider value={{ createdivec }}>
      {children}
    </CreateDeviceContext.Provider>
  );
};

export default CreateDeviceProvider;
