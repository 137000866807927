import React from "react";
import { FaTimes, FaSpinner } from "react-icons/fa";

interface ConfirmDialogProps {
  isOpenn: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
  isLoading: boolean;
  successMessage?: string;
  errorMessage?: string;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  isOpenn,
  onClose,
  onConfirm,
  title,
  message,
  isLoading,
  successMessage,
  errorMessage,
}) => {
  if (!isOpenn) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-96">
        <div className="flex justify-between items-center mb-4" dir="rtl">
          <h2 className="text-xl font-bold">{title}</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-800">
            <FaTimes />
          </button>
        </div>
        <p className="mb-6" dir="rtl">{message}</p>
        {successMessage && !isLoading && (
          <div className="mb-4 text-green-400">
            {successMessage}
          </div>
        )}
        {errorMessage && !isLoading && (
          <div className="mb-4 text-red-600">
            {errorMessage}
          </div>
        )}
        <div className="flex justify-end space-x-4 gap-2">
          <button
            onClick={onClose}
            className="px-3 py-1 rounded hover:bg-slate-300"
          >
            إلغاء
          </button>
          <button
            onClick={onConfirm}
            disabled={isLoading}
            className={`px-3 py-1 rounded ${
              isLoading ? "bg-red-300 cursor-not-allowed" : "bg-red-500 hover:bg-red-600"
            } text-white flex items-center justify-center space-x-2`}
          >
            {isLoading ? (
              <>
                <FaSpinner className="animate-spin" />
                <span>جاري الحذف...</span>
              </>
            ) : (
              <span>حذف</span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDialog;
