import React, { useState, useEffect, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import { DeviceMasterContext } from "../../../../context/devices/DeviceMasterContext";
import { AuthContext } from "../../../../context/AuthContext";
import axios from "axios";
import SwitchToggle from "../settings/SwitchToggle";
import { FaSpinner } from "react-icons/fa";
import { Device } from "../../../../interfaces/Device";

const DeviceDetails: React.FC = () => {
  const { deviceId } = useParams<{ deviceId: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [deviceDetails, setDeviceDetails] = useState<Device | undefined>(
    undefined
  );
  const [isSwitchLoading, setIsSwitchLoading] = useState<boolean>(false);
  const [isSwitchChecked, setIsSwitchChecked] = useState<boolean>(false);

  const { deviceData, fetchDeviceMaster } = useContext(DeviceMasterContext);
  const { token } = useContext(AuthContext);

  const fetchDeviceDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      if (deviceData && deviceData.data) {
        const foundDevice = deviceData.data.find(
          (device: Device) => device.deviceId === deviceId
        );
        if (foundDevice) {
          setDeviceDetails(foundDevice);
          setIsSwitchChecked(foundDevice.payment_status);
        } else {
          console.warn("Device not found in deviceData.");
        }
      } else {
        console.warn("deviceData or deviceData.data is undefined.");
      }
    } catch (error) {
      console.log("Error fetching device details:", error);
    } finally {
      setIsLoading(false);
    }
  }, [deviceId, deviceData]);

  useEffect(() => {
    fetchDeviceDetails();
  }, [fetchDeviceDetails]);

  const handleSwitchChange = async (checked: boolean) => {
    if (deviceData && deviceData.data) {
      const foundDevice = deviceData.data.find(
        (device: Device) => device.deviceId === deviceId
      );
      if (foundDevice) {
        if (deviceDetails) {
          setIsSwitchLoading(true);
          try {
            const response = await axios.post(
              "https://whats1.sky.sky4system.com/api/user/Device-Service",
              {
                deviceToken: deviceDetails.Token,
                deviceId: deviceDetails.deviceId,
                payment_status: checked ? "1" : "0",
              },
              {
                headers: {
                  "auth-token": token,
                },
              }
            );

            if (response.data.status === true) {
              setIsSwitchChecked(response.data.data.payment_status === "1");
              console.log("API Response:", response.data);
              fetchDeviceMaster();
            }
            await fetchDeviceDetails();
            return response.data;
          } catch (error) {
            console.log("Error updating payment status:", error);
          } finally {
            setIsSwitchLoading(false);
          }
        }
      }
    }
  };

  return (
    <div className="p-4">
      {isLoading ? (
        <div></div>
      ) : (
        <div>
          {deviceDetails ? (
            <div>
              <div className="flex justify-between">
                <div>
                  <SwitchToggle
                    initialChecked={isSwitchChecked}
                    onChange={handleSwitchChange}
                    disabled={isSwitchLoading}
                  />
                </div>
                <div className="text-lg font-bold text-gray-700"><span>تفعيل الجهاز</span></div>
              </div>
            </div>
          ) : (
            <div className="text-center py-4">
              <div className="flex justify-center items-center">
                <FaSpinner className="animate-spin h-5 w-5 mr-3 text-gray-500" />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DeviceDetails;
