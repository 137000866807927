import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../Sidebar";
import Header from "../Header";

const Layout: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleResize = () => {
    if (window.innerWidth >= 1024) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="h-screen overflow-y-auto custom-scrollbarr selection:bg-red-100">
      <Header toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <main
        className={`flex-1 pb-0 bg-white transition-all duration-300 ease-in-out ${
          isSidebarOpen ? "bg-black" : "opacity-100"
        } ${isSidebarOpen ? "ml-16 sm:ml-64 " : "ml-0 sm:ml-16 "} `}
      >
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
