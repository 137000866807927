import React, { useContext, useMemo, useState } from "react";
import Select, { components, MultiValue } from "react-select";
import "./css/GroupsSelect.css";
import { ContactContext } from "../../../../context/contactsgroups/ContactContext";
import colourStyles from "./colourStyles";

interface OptionType {
  value: string;
  label: string;
  groupDetails?: any;
}

interface ContactSelectProps {
  onContactSelect: (selectedGroupIds: string[]) => void;
}

const ContactSelect: React.FC<ContactSelectProps> = ({ onContactSelect }) => {
  const { ContactsData } = useContext(ContactContext);
  const [selectedOptions, setSelectedOptions] = useState<OptionType[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const options = useMemo(() => {
    if (!ContactsData || !ContactsData.data) return [];

    return ContactsData.data.data.map((conatct: any) => {
      const numericId = conatct.id.split("@")[0];

      return {
        value: numericId,
        label: conatct.name || "لا يوجد جهات اتصال",
        conatctDetails: conatct,
      };
    });
  }, [ContactsData]);

  const handleChange = (newValue: MultiValue<OptionType>) => {
    const selected = newValue as OptionType[];
    setSelectedOptions(selected);

    const selectedGroupIds = selected.map((option) => option.value);
    // console.log('Selected Options:', selected);
    // console.log('Selected Group IDs:', selectedGroupIds);

    onContactSelect(selectedGroupIds);
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      // Deselect all
      setSelectedOptions([]);
      onContactSelect([]);
    } else {
      // Select all
      setSelectedOptions(options);
      const allGroupIds = options.map((option: { value: any }) => option.value);
      onContactSelect(allGroupIds);
    }
    setSelectAll(!selectAll);
  };
  const customMenu = (props: any) => {
    const { innerRef, innerProps } = props;

    return (
      <div ref={innerRef} {...innerProps} className="react-select-menu">
        <div className="p-2">
          <div className="select-all-option">
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAllChange}
              id="select-all"
            />
            <label htmlFor="select-all" className="ml-2">
              {selectAll ? "إلغاء الكل" : "اختيار الكل"}
            </label>
          </div>
        </div>
        <components.Menu {...props} />
      </div>
    );
  };
  return (
    <div className="mt-8">
      <Select
        options={options}
        isMulti
        onChange={handleChange}
        value={selectedOptions}
        components={{ Menu: customMenu }}
        placeholder="...اختار جهات الاتصال "
        classNamePrefix="react-select"
        closeMenuOnSelect={false}
        styles={colourStyles}
      />
    </div>
  );
};

export default ContactSelect;
