import axios from "axios";
type NotificationType = { message: string; type: "success" | "error" };

export const handleOpen = async (
  device: any,
  setSelectedDevice: React.Dispatch<React.SetStateAction<any | null>>
) => {
  setSelectedDevice(device);
};

export const handleGetDevice = async (
  selectedDevice: any,
  token: string | null,
  fetchDeviceMaster: () => void,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setIsQrCodeModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedDevice: React.Dispatch<React.SetStateAction<any | null>>,
  setQrCodeData: React.Dispatch<React.SetStateAction<string | null>>,
  setNotification: React.Dispatch<React.SetStateAction<NotificationType | null>>
) => {
  if (selectedDevice) {
    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://whats1.sky.sky4system.com/api/user/getQr",
        {
          deviceToken: selectedDevice.Token,
          deviceId: selectedDevice.deviceId,
        },
        {
          headers: {
            "auth-token": token,
          },
        }
      );

      // console.log(response.data);

      if (response.data.status === true) {
        setQrCodeData(response.data.data.qr);
        // console.log(response.data.data.qr);
        // console.log(response.data);
        fetchDeviceMaster();
        setNotification({
          message: "تم إنشاء رمز الاستجابة السريعة بنجاح",
          type: "success",
        });
      } else {
        setNotification({
          message: "فشل في إنشاء رمز الاستجابة السريعة!",
          type: "error",
        });
      }
    } catch (error) {
      setNotification({ message: "حدث خطأ", type: "error" });
    } finally {
      setIsLoading(false);
      setIsQrCodeModalOpen(true);
      setSelectedDevice(selectedDevice);
    }
  }
};
