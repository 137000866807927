import React from 'react';
import { FaClock } from 'react-icons/fa';

interface TimelineEvent {
  timestamp: number;
  label: string;
  description: string;
  icon?: React.ReactNode;
}

interface TimelineSliderProps {
  events: TimelineEvent[];
  currentTime: number;
  onTimeChange: (time: number) => void;
}

const TimelineSlider: React.FC<TimelineSliderProps> = ({ events, currentTime, onTimeChange }) => {
  const labels = ['1s', '10s', '20s', '30s', '40s', '50s'];

  const calculatePosition = (value: number) => {
    const percentage = (value / 50) * 100;
    return `calc(${percentage}% - 20px)`;
  };

  return (
    <div className="relative flex flex-col justify-center my-4 mx-4 md:mx-auto max-w-4xl">
      {events.map((event, index) => (
        <div key={index} className="mb-4 flex justify-end w-full">
          <div className="ml-4 text-lg flex items-center">
            {event.icon || <FaClock className="mr-2" />}
            {event.description}
          </div>
        </div> 
      ))}
      <div className="w-full mt-2 relative">
        <input
          type="range"
          min="0"
          step="0.1"
          max="50"
          value={currentTime}
          onChange={(e) => onTimeChange(Number(e.target.value))}
          className="w-full h-2 bg-green-200 rounded-lg appearance-none"
          style={{ backgroundSize: `${(currentTime / 50) * 100}% 100%` }}
        />
        <div className="flex justify-between text-sm mt-1">
          {labels.map((label, index) => (
            <span key={index}>{label}</span>
          ))}
        </div>
        <div
          className="absolute top-0 transform translate-y-[-1.5rem] bg-yellow-200 p-1 rounded-full"
          style={{ left: calculatePosition(currentTime) }}
        >
          <span className="text-lg font-bold p-2">{`${currentTime}s`}</span>
        </div>
      </div>
    </div>
  );
};

export default TimelineSlider;
