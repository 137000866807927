import React, { createContext, ReactNode } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

interface CreateUserContextType {
  signup: (
    name: string,
    username: string,
    phone: string,
    email: string,
    password: string,
    navigate: ReturnType<typeof useNavigate>
  ) => Promise<void>;
}

export const CreateUserContext = createContext<CreateUserContextType>({
  signup: async () => {},
});

const CreateUserProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const signup = async (
    name: string,
    username: string,
    phone: string,
    email: string,
    password: string,
    navigate: ReturnType<typeof useNavigate>
  ) => {
    try {
      const formData = new FormData();
      formData.append("username", username);
      formData.append("password", password);
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("role", "2");

      const response = await axios.post(
        "https://whats1.sky.sky4system.com/api/user/createUser",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status === true) {
        console.log(response.data);
        navigate("/login");
      } else if(response.data.msg === 'The email has already been taken.'){
        console.log("The email has already been taken ysser");
      }
      else {
        console.log(response.data);
      }
      console.log(phone);

    } catch (error) {
      console.error(error);
      throw new Error("Invalid credentials");
    }
  };

  return (
    <CreateUserContext.Provider value={{ signup }}>
      {children}
    </CreateUserContext.Provider>
  );
};

export default CreateUserProvider;
