import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import { motion } from "framer-motion";
import { BiSupport } from "react-icons/bi";
import { SiSpeedypage } from "react-icons/si";
import { PiIdentificationBadgeThin } from "react-icons/pi";
import { TbDeviceMobileMessage } from "react-icons/tb";
import { SiChatbot } from "react-icons/si";


import { LuMessagesSquare } from "react-icons/lu";

const ServicesSection: React.FC = () => {
  return (
    <section
      className="dark:bg-gray-900 text-gray-700 dark:text-gray-300 py-12 px-4 sm:px-6 lg:px-8"
      dir="rtl"
    >
      <div className="container mx-auto">
        <motion.h2
          className="text-3xl font-bold mb-8 text-center flex items-center justify-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <FaWhatsapp className="text-[#30aaf0] ml-3" />
          تواصل فعال مع عملائك عبر واتساب
        </motion.h2>
        <motion.p
          className="text-lg mb-8 text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          الآن يمكنك التواصل مع عملائك بشكل فوري وسهل عبر واتساب، المنصة التي
          يستخدمها الجميع على نطاق واسع.
        </motion.p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {[
            {
              icon: (
                <BiSupport className="text-[#30aaf0] h-10 w-10 ml-2" />
              ),
              title: "دعم فني",
              items: [
                "فريق الدعم الفني متاح دائمًا لمساعدتك في أي استفسار أو مشكلة قد تواجهك.",
              ],
            },
            {
              icon: <SiSpeedypage className="text-[#30aaf0] ml-2 h-10 w-10" />,
              title: "سرعة الإرسال",
              items: [
                "أرسل رسائل تحمل اسم نشاطك مع عناوين إرسال متغيرة، لتعزز من تميز رسائلك.",
              ],
            },
            {
              icon: <PiIdentificationBadgeThin className="text-[#30aaf0] ml-2 h-10 w-10" />,
              title: "معرفات المرسل",
              items: [
                "نظام أمان متقدم لحماية بياناتك وخصوصيتك.",
                "تشفير البيانات وحمايتها من الوصول غير المصرح به.",
                "رصد ومراقبة متقدمة لاكتشاف التهديدات الأمنية والتعامل معها بسرعة.",
              ],
            },
            {
              icon: <LuMessagesSquare className="text-[#30aaf0] ml-2 h-10 w-10" />,
              title: "رسائل خدمية",
              items: [
                "تستهدف هذه الرسائل مستخدمين محددين لتقديم بلاغات شخصية أو آلية مثل:مواعيد المستشفيات والعمليات البنكية.",
              ],
            },
            {
              icon: <TbDeviceMobileMessage className="text-[#30aaf0] ml-2 h-10 w-10" />,
              title: "رسائل توعوية",
              items: [
                "يتم إرسال هذه الرسائل ذات المحتوى التوعوي أو الإرشادي إلى عموم المستخدمين أو فئات محددة منهم، من قبل جهات اعتبارية.",
              ],
            },
            {
              icon: <SiChatbot className="text-[#30aaf0] ml-2 h-10 w-10" />,
              title: "الرسائل الذكية",
              items: [
                "تسمح لك بإرسال مجموعة من الرسائل، حيث تحتوي كل رسالة نصية قصيرة على محتوى مخصص يتناسب مع كل عميل.",
              ],
            },
          ].map((feature, index) => (
            <motion.div
            key={index}
            className="bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-300 rounded-3xl shadow-lg p-6"
            initial={{ opacity: 0, scale: 0.1, rotate: 0 }}
            whileInView={{ opacity: 1, scale: 1, rotate: 0 }}
            whileHover={{
              borderColor: "#1060aa", 
              borderWidth: "1px",
            }}
            style={{
              padding: "20px",
              borderRadius: "8px",
            }}
            transition={{ duration: 0.1, delay: index * 0.0 }}
            >
              <h3 className="text-2xl font-semibold mb-4 flex items-center">
                {feature.icon}
                {feature.title}
              </h3>
              <ul className="space-y-4">
                {feature.items.map((item, i) => (
                  <motion.li
                    key={i}
                    className="flex items-start"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: i * 0.2 }}
                  >
                    <span>{item}</span>
                  </motion.li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
