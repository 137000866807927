import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
} from "react";
import axios from "axios";
import { DeviceMasterContext } from "../devices/DeviceMasterContext";
import { AuthContext } from "../AuthContext";

interface GroupsContextType {
  GroupsData: any | null;
  loading: boolean;
}

export const GroupsContext = createContext<GroupsContextType>({
  GroupsData: null,
  loading: false,
});

const GroupsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { deviceData } = useContext(DeviceMasterContext);
  const [GroupsData, setGroupsData] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { userId } = useContext(AuthContext);

  const fetchGroupMaster = useCallback(async () => {
    // Ensure deviceData and userId are not null or undefined
    if (!deviceData || !deviceData.data || !userId) return [];

    const authenticatedDevice = deviceData.data.find(
      (device: any) =>
        device.status === "AUTHENTICATED" && device.id_users === userId
    );

    if (!authenticatedDevice) {
      console.log("No authenticated device found");
      return;
    }

    const devicGId = authenticatedDevice.deviceId;
    const deviceGToken = authenticatedDevice.Token;

    setLoading(true);
    try {
      const response = await axios.post(
        `https://whats1.sky.sky4system.com/api/group/GetGroupList`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          deviceId: devicGId,
          deviceToken: deviceGToken,
        }
      );
      
      // console.log(response.data);
      setGroupsData(response.data);
    } catch (error) {
      console.error("Failed to fetch Groups data", error);
    } finally {
      setLoading(false);
    }
  }, [deviceData, userId]);

  useEffect(() => {
    fetchGroupMaster();
  }, [fetchGroupMaster]);

  return (
    <GroupsContext.Provider value={{ GroupsData, loading }}>
      {children}
    </GroupsContext.Provider>
  );
};

export default GroupsProvider;
