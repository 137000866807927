import React, { useState, useEffect, useContext, useCallback, ChangeEvent } from "react";
import { useParams } from "react-router-dom";
import { DeviceMasterContext } from "../../../../context/devices/DeviceMasterContext";
import { AuthContext } from "../../../../context/AuthContext";
import axios from "axios";
import { Device } from "../../../../interfaces/Device";
import { FaPlus } from "react-icons/fa";

const UpdateWebHook: React.FC = () => {
  const { deviceId } = useParams<{ deviceId: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [deviceDetails, setDeviceDetails] = useState<Device | undefined>();
  const [webhookUrl, setWebhookUrl] = useState<string>("");
  const [message, setMessage] = useState<{ text: string; type: "success" | "error" } | null>(null);

  const { deviceData, fetchDeviceMaster } = useContext(DeviceMasterContext);
  const { token } = useContext(AuthContext);

  const fetchDeviceDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      if (deviceData && deviceData.data) {
        const foundDevice = deviceData.data.find(
          (device: Device) => device.deviceId === deviceId
        );
        if (foundDevice) {
          setDeviceDetails(foundDevice);
          setWebhookUrl(foundDevice.hook_url);
        } else {
          console.warn("Device not found in deviceData.");
        }
      } else {
        console.warn("deviceData or deviceData.data is undefined.");
      }
    } catch (error) {
      console.log("Error fetching device details:", error);
    } finally {
      setIsLoading(false);
    }
  }, [deviceId, deviceData]);

  useEffect(() => {
    fetchDeviceDetails();
  }, [fetchDeviceDetails]);

  const handleUpdateChange = async () => {
    if (deviceDetails) {
      setIsLoading(true);
      setMessage(null);

      if (!validateWebhook(webhookUrl)) {
        setMessage({ text: ".عنوان الويب هوك غير صالح. يرجى إدخال عنوان صالح", type: "error" });
        setIsLoading(false);
        setTimeout(() => setMessage(null), 5000);
        return;
      }

      const formData = new FormData();
      formData.append("webhook_url", webhookUrl);
      formData.append("deviceToken", deviceDetails.Token);
      formData.append("deviceId", deviceDetails.deviceId);

      try {
        const response = await axios.post(
          "https://whats1.sky.sky4system.com/api/user/updatewebhook",
          formData,
          {
            headers: {
              "auth-token": token,
            },
          }
        );
        // console.log(response.data);
        
        if (response.data.status === true) {
          setWebhookUrl(response.data.data.hook_url);
          fetchDeviceMaster();
          setMessage({ text: "تم تحديث عنوان URL لـ Webhook بنجاح!", type: "success" });
        } else {
          setMessage({ text: ".عنوان الويب هوك غير صالح. يرجى إدخال عنوان صالح", type: "error" });
        }
        await fetchDeviceDetails();
        return response.data;
      } catch (error) {
        console.log("Error updating webhook URL:", error);
        setMessage({ text: ".عنوان الويب هوك غير صالح. يرجى إدخال عنوان صالح", type: "error" });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const validateWebhook = (url: string): boolean => {
    const isValid = /^https?:\/\//.test(url);
    return isValid;
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (message) {
      timeout = setTimeout(() => setMessage(null), 5000);
    }
    return () => clearTimeout(timeout);
  }, [message]);

  return (
    <div className="p-4">
      <div className="space-y-2">
        <div className="text-lg flex justify-end font-bold text-gray-700">
          <button
            onClick={handleUpdateChange}
            disabled={isLoading}
            className={`inline-flex justify-end gap-2 px-2 py-2 text-sm font-medium border-gray-400 text-gray-100 hover:bg-blue-600 border border-transparent rounded-md bg-blue-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-100 ${
              isLoading ? "cursor-not-allowed opacity-50" : ""
            }`}
          >
            <span>
              {isLoading ? " webhook تحديث عنوان" : " URL webhook تحديث عنوان"}
            </span>
            <FaPlus />
          </button>
        </div>
        <div className="space-y-1">
          <input
            type="text"
            name="webhookUrl"
            placeholder="https://example.com"
            value={webhookUrl || ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setWebhookUrl(e.target.value);
            }}
            className={`p-2 mt-4 border w-full rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 transition duration-300 ease-in-out transform hover:scale-105 ${
              message?.type === "error" ? "border-red-500" : ""
            }`}
          />
          {message && (
            <p className={`text-sm mt-1 firework-message ${message.type === "error" ? "error-message" : ""}`}>
              {message.text}
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdateWebHook;
